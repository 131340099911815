import moment from 'moment';

jQuery( document ).ready(function($) {
    // Events
    // fetch('https://cdn.seated.com/api/public/v1/artists/b2dc8c46-c9b1-4c09-bad7-4727550517cc/tour-events', {
    //     headers: {
    //         'Accept': 'application/vnd.api+json',
    //         'Content-Type': 'application/vnd.api+json',
    //         'Authorization': '0badb7ed-f217-4dc8-b944-99fbaf816a0a'
    //     }
    // })
    // .then((response) => response.json())
    // .then((data) => {
    //     console.log(data);
    //     const events = $( '#events, #events-inner' );
    //     let html = '';
    //     console.log( data );
    //     if ( data.data.length ) {
    //         html+='<div>'
    //         for ( let item of data.data ) {
    //             let event = item.attributes;
    //             let country = event['venue-country-code'];
    //             let region = (event['venue-country-code'] == 'US')?event['venue-state-or-province']:event['venue-country-code'];
    //             const location = event['venue-city'] + ', ' + region;
    //             html += '<div class="event ' + country + '">';
    //             html += '<div class="event-date">' + moment( event['starts-at-date-local'] ).format( 'MMM DD Y' ) + '</div>';
    //             html += '<div class="event-location">' + location + '</div>';
    //             html += '<div class="event-venue">' + event['venue-name'] + '</div>';
    //             html += '<div class="event-links">';
                
    //             html += '<a href="' + event['primary-link-url'] + '" target="_blank" class="btn"><span>' + event['primary-button-text'] + '</span></a>';

    //             html += '</div>';
    //             html += '</div>';
    //         }
    //         html += '</div>'; 
    //         events.html( html );
    //     } else {
    //         events.html( '<div class="event no-event"><span>No upcoming events.</span></div>' );
    //     }
    // });

    // $('.tour-toggle a').on('click', function(e){
    //     e.preventDefault();
    //     $('#events-inner, #events').removeClass();
    //     $('#events-inner, #events').addClass($(this).attr('data-target'));
    //     $('.tour-toggle a').removeClass('active');
    //     $('.tour-toggle a[data-target=' + $(this).attr('data-target') + ']').addClass('active');
    // });
    // $.ajax( {
    //     url: 'https://rest.bandsintown.com/artists/half•alive/events?app_id=45PRESS_half_alive',
    //     method: 'GET',
    //     dataType: 'json',
    //     error: () => {
    //     },
    //     success: data => {
    //         
    //     },
    // } );

    //Scroll
    $('.scroll').on('click', function(e) {
        const TARGET = $(this).attr('href');

        e.preventDefault();

        //Return to Home
        $('article').hide();
        $('body, html').animate({
            scrollTop: 0
        });
        $('#home').fadeIn();

        $('body, html').animate({
            scrollTop: $(TARGET).offset().top
        });
    });

    $('.interior').on('click', function(e){
        e.preventDefault;
        const TARGET = $(this).attr('href');

        $('article').hide();
        $('body, html').animate({
            scrollTop: 0
        });
        
        if (TARGET == '#home'){
            $("#tour").fadeIn();
        }

        $(TARGET).fadeIn();
    })

    $('article:not(#home)').hide();
    $('#tour').show();
    
    function debounce(method, delay) {
        clearTimeout(method._tId);
        method._tId= setTimeout(function(){
            method();
        }, delay);
    }
    
    $(window).on('scroll', function() {
        handleScroll();
    });

    function handleScroll() {
        var scroll = $(window).scrollTop();
        var max = $( document ).height();
        var percent = scroll/max;

        $('.desktop.doodle').css('transform','translateY(' + ( percent * 300 ) + 'px)');
    }

    if (window.location.hash){
        let TARGET = '#' + window.location.hash.substring(1);

        $('article').hide();
        $('body, html').animate({
            scrollTop: 0
        });
        if (TARGET == '#home'){
            $("#tour").fadeIn();
        }
        $(TARGET).fadeIn();
    }

    // setTimeout(function(){
    //     $('#events-inner').html($('#events').html());
    //     $('#events-inner>div').height($('#events>div').height());
    // }, 2000);
}); 